<template>
  <section class="section contest" id="contest">
    <div class="container">
      <div class="section-title">КОНКУРС</div>
      <div class="contest__row">
        <div class="contest__right-block">
          <ul class="contest-steps">
            <li v-for="(item, i) in steps" :key="i" class="contest-steps__item">
              <div class="contest-steps__item-index">{{ i + 1 }}</div>
              <div class="contest-steps__item-content">
                <span class="_yellow">{{ item.title }}</span>
                <p v-html="item.text"></p>
              </div>
            </li>
          </ul>
        </div>
        <button
          @click="$metrika.reachGoal('contest_2_screen'), toCheckUpload()"
          class="contest__btn btn btn--primary btn--icon"
        >
          <span>Зарегистрировать чек</span>
          <img
            class="btn__icon"
            src="../assets/images/icons/arrow_1.svg"
            alt=""
          />
        </button>
        <div class="contest__left-block">
          <img
            src="../assets/images/products-2.png"
            class="contest__left-block-img visible-xs"
            alt=""
          />
          <div class="contest__left-block-title _yellow">ВЫИГРАЙТЕ ПРИЗ</div>
          <div class="contest__left-block-text">Подарочный набор от JARDIN</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    page: 1,
    perPage: 1,
    pages: [],
    steps: [
      {
        title: "зарегистрируйте",
        text: "чек и&nbsp;получите доступ к&nbsp;разделу <span class='_to-gallery _undeline-link'>фотогалерея</span>",
      },
      {
        title: "загружайте",
        text: "ваши фотографии с&nbsp;кофе Jardin на&nbsp;сайт акции в&nbsp;раздел <span class='_to-gallery _undeline-link'>фотогалерея</span>",
      },
      {
        title: "Собирайте голоса",
        text: "Для голосования требуется зарегистрировать валидный чек: один чек дает право проголосовать один раз",
      },
    ],
  }),
  mounted() {
    const $this = this;
    const anchors = document.querySelectorAll('._to-gallery');
    anchors.forEach(element => {
      element.addEventListener('click', function(){
        
        document.getElementById("gallery").scrollIntoView({
          behavior: 'smooth'
        });
        
      })
    });
  }
};
</script>

<style lang="scss" scoped>
.contest {
  padding-top: rem(98px);
  z-index: 2;
  &::before {
    content: "";
    position: absolute;
    left: 50%;
    top: rem(-100px);
    transform: translateX(-50%);
    display: block;
    width: 200%;
    height: rem(300px);
    background: url("../assets/images/lines.svg") no-repeat center;
    background-size: 100%;
  }
  @media (orientation: landscape) {
    padding-top: rem(90px);
    &::before {
      top: rem(-140px);
      width: 100%;
      height: rem(300px);
    }
  }
  &__row {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding-top: rem(3px);
    @media (orientation: landscape) {
      width: 89%;
      padding-top: rem(0px);
      flex-direction: row;
      justify-content: space-between;
      background: url("../assets/images/products.png") no-repeat top center;
      background-size: rem(950px);
    }
  }
  &-steps {
    width: 100%;
    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: rem(35px);
      &:last-of-type {
        margin-bottom: rem(0px);
      }
      @media (orientation: landscape) {
        width: rem(345px);
        margin-bottom: rem(25px);
        &:last-of-type {
          margin-bottom: rem(30px);
        }
      }
      &-index {
        display: flex;
        align-items: center;
        justify-content: center;
        @include size(rem(52px));
        margin-right: rem(20px);
        flex-shrink: 0;
        background: $gold;
        font-size: rem(30px);
        line-height: 1;
        border-radius: 50%;
      }
      &-content {
        span {
          display: block;
          margin-bottom: rem(5px);
          font-size: rem(20px);
          line-height: 1;
        }
        p {
          font-family: $font-reg;
          font-size: rem(14px);
          line-height: rem(17px);
        }
        @media (orientation: landscape) {
          span {
            margin-bottom: rem(10px);
            font-size: rem(24px);
          }
          p {
            font-size: rem(16px);
            line-height: rem(19px);
          }
        }
      }
    }
  }
  &__btn {
    align-self: flex-end;
    order: 1;
    @media (orientation: landscape) {
      order: 0;
    }
  }
  &__left-block {
    padding-top: rem(20px);
    text-align: center;

    &-title {
      margin-bottom: rem(13px);
      font-size: rem(24px);
      line-height: 1;
    }
    &-text {
      margin-bottom: rem(17px);
      font-size: rem(14px);
      line-height: 1;
      font-family: $font-reg;
    }
    &-img {
      max-width: none;
      height: rem(255px);
      object-fit: contain;
      object-position: center;
      margin-left: rem(-15px);
      margin-bottom: rem(22px);
    }
    @media (orientation: landscape) {
      padding-top: rem(75px);
      padding-right: rem(35px);
      text-align: left;
      &-title {
        margin-bottom: rem(20px);
        font-size: rem(32px);
      }
      &-text {
        margin-bottom: 0;
        font-size: rem(18px);
      }
    }
  }
  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: rem(30px);
    color: #fff;
    &-item {
      display: none;
      margin: 0 rem(8px);
      font-size: rem(19px);
      background: none;
      cursor: pointer;
      &:first-of-type,
      &:last-of-type {
        display: block !important;
      }
      &.current {
        cursor: default;
      }
      &.visible {
        display: block;
      }
      &.first::after {
        content: "...";
        margin-left: rem(8px);
      }
      &.last::before {
        content: "...";
        margin-right: rem(8px);
      }
    }
  }
}
</style>
