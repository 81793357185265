<template>
  <div class="home">
    <Hero />
    <Steps />
    <AppPartners />
    <AppPrizes />
    <AppContest />
    <AppVoting />
    <AppWinners />
    <AppFaq />
  </div>
</template>

<script>
import AppWinners from "../components/AppWinners.vue";
import AppFaq from "../components/AppFaq.vue";
import AppVoting from "../components/AppVoting.vue";
import AppContest from "../components/AppContest.vue";
import AppPrizes from "../components/AppPrizes.vue";
import AppPartners from "../components/AppPartners.vue";
import Hero from "@/components/AppHero.vue";
import Steps from "@/components/AppSteps.vue";
export default {
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),

  mounted() {
    // this.$modal.show("delivery");
    this.$modal.show("temporarily_unavailable");
    this.$parent.showHeader = true;
    this.$parent.showFooter = true;
    const t = this;
    let doGoal25 = true;
    let doGoal50 = true;
    let doGoal75 = true;
    let doGoal100 = true;
    const sections = document.querySelectorAll(".section");
    const navs = document.querySelectorAll(".header__nav-item");

    window.addEventListener("scroll", () => {
      let scrollPosition = window.scrollY + 80;
      sections.forEach((section) => {
        if (scrollPosition >= section.offsetTop) {
          section.classList.add("active");
          navs.forEach((nav) => {
            nav.classList.remove("active");
          });
          if (document.querySelector(`[data-menuanchor='${section.id}']`)) {
            document
              .querySelector(`[data-menuanchor='${section.id}']`)
              .classList.add("active");
          }
        }
      });
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        document.body.offsetHeight / 4
      ) {
        if (doGoal25) {
          t.$metrika.reachGoal('scroll25');
          doGoal25 = false;
        }
      }
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        document.body.offsetHeight / 2
      ) {
        if (doGoal50) {
          t.$metrika.reachGoal('scroll50');
          doGoal50 = false;
        }
      }
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        ((document.body.offsetHeight / 4) * 3)
      ) {
        if (doGoal75) {
          t.$metrika.reachGoal('scroll75');
          doGoal75 = false;
        }
      }
     
      if (
        window.innerHeight + Math.round(window.scrollY) >=
        document.body.offsetHeight
      ) {
        if (doGoal100) {
          t.$metrika.reachGoal('bottom');
          doGoal100 = false;
        }
      }
    });
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },

  components: {
    Hero,
    Steps,
    AppPartners,
    AppPrizes,
    AppContest,
    AppVoting,
    AppFaq,
    AppWinners,
  },
};
</script>

<style lang="scss"></style>
