<template>
  <div class="form-select">
    <div class="form__caption" v-if="labelText">{{ labelText }}</div>
    <v-select
      :searchable="false"
      :options="options"
      v-model.trim="retValue"
      :name="name"
      @focus="errorReset()"
      @change="onInput()"
      @input="onInput(),errorReset()"
      :class="className"
    >
      <span slot="no-options">Ничего не найдено</span>
    </v-select>

    <div class="error-hint" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      retValue: this.field,
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },
  },
  props: ["options", "field", "name", "error", "labelText", "className"],
};
</script>

<style lang="scss">
.form-select {
  position: relative;
  max-width: 100%;
  width: 100%;
  margin: 0 auto 15px;
  z-index: 10;
  &.error {
    .vs__dropdown-toggle {
      border-color: $orange;
    }
  }
  &._golden {
    width: rem(178px);
    max-width: 100%;
    margin-bottom: 0;
    .v-select {
      .vs__dropdown-toggle {
        background: linear-gradient(90deg, #dfc383 0%, #987f54 100%);
        border-radius: rem(30px);
        border: none;
        box-shadow: none;
      }
      .vs__selected {
        width: 100%;
        justify-content: center;
        text-align: center;
        font-family: $font-bold;
        text-transform: uppercase;
      }
      .vs__open-indicator {
        width: rem(30px);
        height: rem(30px);
        margin-right: rem(5px);
        background-color: transparent;
        background-image: url("../../assets/images/icons/arrow_1.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      .vs__dropdown-option--highlight,
      .vs__dropdown-option--selected {
        background: #FFE189;
      }
      &.vs--open .vs__open-indicator {
        transform: rotate(90deg);
      }
    }
  }
  .v-select {
    height: rem(40px);
    font-family: $font-reg;
    text-transform: none;
    &._bordered {
      .vs__open-indicator {
        width: rem(34px);
        height: rem(34px);
        background-color: $yellow;
        background-image: url("../../assets/images/select_arrow.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
      .vs__selected {
        position: relative;
        color: #1a1511;
        padding: 2px 5px 0 0;
      }
      .vs__dropdown-toggle {
        border: 2px solid #912982;
        border-radius: 20px;
      }
      .vs__dropdown-menu {
        width: 100%;
        min-width: auto;
      }
    }
  }
  .vs__dropdown-toggle {
    position: relative;
    display: flex;
    align-items: center;
    line-height: 1;
    height: 100%;
    z-index: 1001;
    background: #ffffff;
    border: none;
    box-sizing: border-box;
    border-radius: rem(30px);
    padding: 0px rem(0px) 0 rem(20px);
    border: $field-border;
  }

  .vs--open .vs__dropdown-toggle {
    border-radius: rem(30px);
    box-shadow: none;
  }
  .vs--open .vs__open-indicator {
  }

  .vs__selected-options {
    padding: 0;
  }

  .vs__search {
    padding: 0;
    margin: 0;
    border: 0;
    color: #1a1511;
    font-size: 13px;
    text-transform: uppercase;

    &:focus {
      border: 0;
      padding: 0;
      margin: 0;
      font-size: 13px;
    }
  }

  .vs__actions {
    padding: 0px 0px 0 3px;
  }

  .vs__clear {
    display: none;
  }

  .vs__open-indicator {
    width: rem(34px);
    height: 100%;
    padding: rem(3px);
    background-image: url("../../assets/images/icons/arrow_2.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: rem(10px);
    transition: 0s;
    path {
      display: none;
    }
  }

  .vs__selected {
    bottom: 0;
    padding: 0;
    margin: 0;
    color: #3c140a;
    font-size: rem(14px);
    line-height: 1;
    text-transform: none;
    letter-spacing: 0.01em;
  }

  .vs--single.vs--open .vs__selected {
    position: relative;
    opacity: 1;
  }

  .vs__dropdown-menu {
    border: 0;
    margin-top: rem(3px);
    padding: rem(0px);
    max-height: none;
    // background: rgba(238, 178, 36, 0.5);
    border-radius: rem(10px);
    z-index: 100;
  }

  .vs__dropdown-option {
    cursor: pointer;
    background: #fff;
    padding: rem(10px) rem(20px);
    text-transform: none;
    font-size: rem(14px);
    line-height: 1;
    letter-spacing: 0.01em;
    color: #0B0909;
    // border-radius: 0.3rem;
  }

  .vs__dropdown-option--highlight {
    color: #0B0909;
    background-color: #FFE189;
  }
  @media (orientation: landscape) {
    margin-bottom: rem(15px);
    &._golden {
      width: rem(278px);
      .v-select {
        .vs__open-indicator {
          width: rem(40px);
          height: rem(40px);
        }
      }
    }
    .v-select {
      height: $field-height-lg;
    }
    .vs__dropdown-toggle {
      padding: 0px rem(0px) 0 rem(20px);
    }
    .vs__dropdown-menu {
      border-radius: rem(20px);
    }
    .vs__selected,
    .vs__dropdown-option {
      font-size: $field-font-size-lg;
    }
    .vs__open-indicator {
      width: $field-height-lg;
      height: $field-height-lg;
      background-size: rem(10px);
    }
    .vs__dropdown-option {
      padding: rem(10px) rem(20px);
      line-height: rem(22px);
    }
  }
}
</style>
