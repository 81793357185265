<template>
  <div class="signup">
    <div class="signup__fields">
      <inputText
        label="Имя"
        :field="first_name"
        size="lg"
        :class="{
          error:
            validationStatus($v.first_name) || this.errorMessage.first_name,
        }"
        :error="
          (!$v.first_name.required && $v.first_name.$error
            ? 'Обязательное поле'
            : !$v.first_name.rus && $v.first_name.$error
            ? 'Введите текст на русском языке'
            : '') || this.errorMessage.first_name
        "
        @input="first_name = $event"
      />
      <inputText
        label="Фамилия"
        :field="last_name"
        size="lg"
        :class="{
          error: validationStatus($v.last_name) || this.errorMessage.last_name,
        }"
        :error="
          (!$v.last_name.required && $v.last_name.$error
            ? 'Обязательное поле'
            : !$v.last_name.rus && $v.last_name.$error
            ? 'Введите текст на русском языке'
            : '') || this.errorMessage.last_name
        "
        @input="last_name = $event"
      />

      <inputText
        label="E-mail"
        :field="email"
        size="lg"
        type="email"
        :class="{
          error: validationStatus($v.email) || this.errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || this.errorMessage.email
        "
        @input="email = $event"
      />
      <inputText
        label="Телефон"
        :field="phone"
        size="lg"
        @input="phone = $event"
        type="tel"
        :mask="'+7(###) ###-##-##'"
        :class="{
          error: validationStatus($v.phone) || this.errorMessage.phone,
        }"
        :error="
          (!$v.phone.required && $v.phone.$error
            ? 'Обязательное поле'
            : !$v.phone.minLength && $v.phone.$error) || this.errorMessage.phone
        "
      />
    </div>

    <div class="form__checkboxes">
      <checkBox
        color="_center"
        :label="`Я согласен с <a href='${publicPath}docs/rules.pdf' class='_undeline-link _bold' target='_blank'>правилами акции</a>`"
        :field="checkbox"
        :class="{
          error: validationStatus($v.checkbox) || this.errorMessage.checkbox,
        }"
        :error="this.errorMessage.checkbox"
        @input="checkbox = $event"
      />
      <checkBox
        color="white"
        :label="`Я&nbsp;даю согласие на&nbsp;обработку <a href='${publicPath}docs/terms_of_use.pdf' class='_undeline-link _bold' target='_blank'>персональных данных</a>`"
        :field="checkbox2"
        :class="{
          error: validationStatus($v.checkbox2) || this.errorMessage.checkbox2,
        }"
        :error="this.errorMessage.checkbox2"
        @input="checkbox2 = $event"
      />
    </div>
    <div class="form__footer">
      <button
        type="button"
        class="btn btn--primary btn--loader-icon w-100"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Зарегистрироваться<img
          src="../../assets/images/icons/spinner.svg"
          class="loader"
          alt=""
        />
      </button>
      <div class="form__another-option">
        <span>Есть профиль?</span>
        <div @click="showLogInModal()" class="_undeline-link _yellow">
          Войти
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

export default {
  name: "SignupForm",
  props: ["network"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      ready: false,
      email: null,
      first_name: null,
      last_name: null,
      phone: null,
      city: null,
      checkbox: false,
      checkbox2: false,
      dadataOptionsCity: {
        bounds: "city-city",
        type: "ADDRESS",
        geoLocation: false,
      },
      errorMessage: {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        city: null,
        checkbox: null,
        checkbox2: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    email: { required, email },
    first_name: { required, rus },
    last_name: { required, rus },
    phone: { required },
    checkbox: {
      required,
      sameAs: sameAs(() => true),
    },
    checkbox2: {
      required,
      sameAs: sameAs(() => true),
    },
  },
  watch: {
    checkbox(newVal) {
      if (!newVal) {
        this.$store.commit("SET_AGREE", false);
      }
    },
  },
  methods: {
    selectSuggestion(suggestion) {
      this.city = suggestion.data.city;
    },
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
        city: null,
        checkbox: null,
        checkbox2: null,
      };
    },
    showRules() {
      if (!this.$store.getters.agree) {
        this.$modal.show("document_popup", {
          src: this.publicPath + "docs/rules.pdf",
        });
      }
    },
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const formData = {
          login: this.email,
          name: this.first_name,
          surname: this.last_name,
          phone: this.phone,
          agree_1: this.checkbox ? 1 : 0,
          agree_2: this.checkbox2 ? 1 : 0,
        };
        this.$store
          .dispatch("SignUp", formData)
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.email) {
                [this.errorMessage.email] = r.error.email || "";
                fieldsError = true;
              } else if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                fieldsError = true;
              }
              if (r.error && r.error.phone) {
                [this.errorMessage.phone] = r.error.phone;
                fieldsError = true;
              }
              if (r.error && r.error.city) {
                [this.errorMessage.city] = r.error.city;
                fieldsError = true;
              }
              if (r.error && r.error.name) {
                [this.errorMessage.first_name] = r.error.name;
                fieldsError = true;
              }
              if (r.error && r.error.surname) {
                [this.errorMessage.last_name] = r.error.surname;
                fieldsError = true;
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! " + r.error,
                });
              }
            } else {
              this.$metrika.reachGoal('registration_success');
              this.adhighRegistrationSuccess();
              this.$modal.hide("signup");
              this.$modal.show("success_signup", {
                title: "ваша регистрация прошла успешно",
              });
            }
          })
          .catch((e) => {
            this.submitStatus = null;
            console.log(e);
          });
      }
    },
    showLogInModal() {
      this.$modal.hide("signup");
      this.$modal.show("authorization");
    },
  },
  mounted() {},
  components: {
    inputText,
    checkBox,
  },
};
</script>
<style lang="scss"></style>
