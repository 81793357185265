<template>
  <div class="feedback">
    <form class="form" :key="indexForm">
      <inputText
        label="Имя"
        :field="name"
        @input="name = $event"
        :class="{
          error: validationStatus($v.name) || this.errorMessage.name,
        }"
        :error="
          (!$v.name.required && $v.name.$error
            ? 'Обязательное поле'
            : !$v.name.rus && $v.name.$error
            ? 'Введите текст на русском языке'
            : '') || this.errorMessage.name
        "
      />
      <inputText
        label="E-mail"
        :field="email"
        @input="email = $event"
        :class="{
          error: validationStatus($v.email) || this.errorMessage.email,
        }"
        :error="
          (!$v.email.required && $v.email.$error
            ? 'Обязательное поле'
            : !$v.email.email && $v.email.$error
            ? 'Некорректный e-mail'
            : '') || this.errorMessage.email
        "
      />
      <Select
        labelText="Тема обращения"
        :options="[
          { id: 1, label: 'Регистрация в акции' },
          { id: 2, label: 'Регистрация чека' },
          { id: 3, label: 'Призовой фонд' },
          { id: 4, label: 'Вопросы по правилам' },
          { id: 5, label: 'Технические вопросы' },
          { id: 6, label: 'Другое' },
        ]"
        placeholder="Тема"
        @input="subject = $event.id"
        :class="{
          error: validationStatus($v.subject) || errorMessage.subject,
        }"
        :error="
          (!$v.subject.required && $v.subject.$error
            ? 'Обязательное поле'
            : '') || errorMessage.subject
        "
      />
      <textArea
        label="Текст обращения"
        :field="message"
        @input="message = $event"
        :class="{
          error: validationStatus($v.message) || this.errorMessage.message,
        }"
        :error="
          (!$v.message.required && $v.message.$error
            ? 'Обязательное поле'
            : '') || this.errorMessage.message
        "
      />
      <File
        :chosenFileName="chosenFileName"
        @submitMedia="submitMedia"
        @deleteFile="deleteFile"
      />
      <div class="form__checkboxes">
        <checkBox
          :label="`Соглашаюсь с&nbsp;<a href='${publicPath}docs/rules.pdf' class='_undeline-link _yellow' target='_blank'>Правилами участия</a> и&nbsp;<a href='${publicPath}docs/terms_of_use.pdf' class='_undeline-link _yellow' target='_blank'>обработкой персональных данных</a>`"
          :field="checkbox2"
          :class="{
            error: validationStatus($v.checkbox2) || errorMessage.checkbox2,
          }"
          :error="errorMessage.checkbox2"
          @input="checkbox2 = $event"
        />
      </div>

      <div class="form__footer">
        <button
          type="button"
          class="btn btn--primary btn--loader-icon w-100"
          @click="submit()"
          :class="{ loading: submitStatus == 'PENDING' }"
        >
          Отправить
          <img
            src="../../assets/images/icons/spinner.svg"
            class="loader"
            alt=""
          />
          <img
            class="btn__icon"
            src="../../assets/images/icons/arrow_1.svg"
            alt=""
          />
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import inputText from "@/components/form/inputText.vue";
import checkBox from "@/components/form/checkBox.vue";
import textArea from "@/components/form/textArea.vue";
import File from "@/components/form/inputFile.vue";
import Select from "@/components/form/inputSelect.vue";

import { required, email, sameAs } from "vuelidate/lib/validators";
const rus = (value) => /^[А-ЯЁа-яё ]+$/.test(value);

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export default {
  name: "FeedbackForm",
  data() {
    return {
      ready: false,
      publicPath: "/",
      indexForm: 1,
      name: null,
      email: null,
      message: null,
      subject: null,
      checkbox: false,
      checkbox2: false,
      attach: "",
      errorMessage: {
        email: null,
        name: null,
        subject: null,
        message: null,
        checkbox: null,
        checkbox2: null,
      },

      submitStatus: null,

      chosenFileName: {},
    };
  },

  validations: {
    message: { required },
    email: { required, email },
    name: { required, rus },
    subject: { required },
    checkbox2: {
      required,
      sameAs: sameAs(() => true),
    },
  },

  methods: {
    submitMedia(e) {
      this.chosenFileName = e;
      console.log(e[0]);
      this.attach = e[0];
    },
    deleteFile() {
      this.chosenFileName = {};
      this.attach = "";
    },

    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        email: null,
        name: null,
        subject: null,
        message: null,
        checkbox2: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    submit() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const formData = new FormData();

        formData.append("file", this.attach);
        formData.append("name", this.name);
        formData.append("email", this.email);
        formData.append("subject_id", this.subject);
        formData.append("text", this.message);
        formData.append("agree", 1);
        const data = {
          fields: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        this.$store
          .dispatch("SendFeedback", data)
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.submitStatus = null;

              this.captcha = null;
              let fieldsError;
              if (r.error && r.error.email) {
                [this.errorMessage.email] = r.error.email;
                fieldsError = true;
              } else if (r.error && r.error.login) {
                [this.errorMessage.email] = r.error.login;
                fieldsError = true;
              }

              if (r.error && r.error.name) {
                [this.errorMessage.name] = r.error.name;
                fieldsError = true;
              }

              if (r.error && r.error.text) {
                [this.errorMessage.message] = r.error.text;
                fieldsError = true;
              }
              if (r.error && r.error.subject_id) {
                [this.errorMessage.subject] = r.error.subject_id;
                fieldsError = true;
              }
              if (r.error && r.error.accept1) {
                [this.errorMessage.checkBox] = r.error.accept1;
                fieldsError = true;
              }
            } else {
              this.indexForm++;
              this.indexForm2++;
              setTimeout(() => {
                this.$v.$reset();
                this.message = null;
              }, 0);
              this.submitStatus = null;
              this.$modal.hide("feedback");
              this.$modal.show("common_success", {
                title: "Спасибо за&nbsp;обращение!",
                text: `ваш вопрос будет обработан в&nbsp;течение 72&nbsp;часов. <br><br>Ответ будет отправлен на&nbsp;${this.email}`,
                btnClose: true,
                successIcon: true,
              });
      
              this.gtmEvent("feedback_sent");
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.user) {
        this.indexForm++;
        this.name = this.user.name;
        this.email = this.user.login;
        // this.checkbox = true;
        // this.checkbox2 = true;
      }
    }, 1000);
  },
  components: {
    inputText,
    textArea,
    checkBox,
    File,
    Select,
  },
};
</script>

<style lang="scss"></style>
