<template>
  <modal
    :name="name"
    :adaptive="true"
    :scrollable="true"
    height="auto"
    width="800"
    @before-open="beforeOpen"
    :clickToClose="!withoutClosing"
  >
    <div
      class="modal"
      :class="[
        'modal-' + name,
        size ? 'modal--' + size : 'modal--md',
        success ? 'modal-success' : '',
        withoutClosing ? '_withoutClosing' : '',
      ]"
    >
      <div
        class="modal__close"
        v-if="!withoutClosing"
        @click="closeModal(name)"
      ></div>
      <img
        v-if="successIcon"
        src="../assets/images/icons/success.svg"
        alt=""
        class="modal__success-icon"
      />
      <img
        v-if="errorIcon"
        src="../assets/images/icons/error.svg"
        alt=""
        class="modal__success-icon"
      />
      <div class="modal__header" v-if="title">
        <h2
          class="modal__title"
          :class="success ? 'modal-success__title' : ''"
          v-html="title"
        ></h2>
      </div>
      <slot name="content"></slot>
    </div>
  </modal>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
    },
    name: {
      type: String,
    },
    title: {
      type: String,
    },
    success: {
      type: Boolean,
    },
    successIcon: {
      type: Boolean,
    },
    errorIcon: {
      type: Boolean,
    },
    withoutClosing: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showBurger() {
      this.closeModal(this.name);
      let header = document.querySelector(".header");
      if (!header.classList.contains("header--mob_active"))
        header.classList.add("header--mob_active");
      else header.classList.remove("header--mob_active");
    },
    closeModal(name) {
      this.$emit("closeModal", name);
      if (name === "signup") {
        // this.gtmEvent("drop_success", "do", "internal");
      }
    },
    beforeOpen(event) {
      this.$parent.beforeOpen(event);
    },
  },
  mounted() {
    // this.$modal.show("success_modal");
  },
};
</script>

<style lang="scss">
.vm--container {
  overflow: auto;
  z-index: 999999 !important;
  .vm--overlay {
    background: rgba($brown, 0.85);
    backdrop-filter: blur(6px);
  }
}
.vm--modal {
  padding: 0;
  justify-content: center;
  overflow: visible !important;
  display: flex;
  min-height: 100%;
  height: auto !important;
  top: 0 !important;
  align-items: flex-start;
  box-shadow: none !important;
  background-color: transparent !important;

  @media (min-width: $md) and (orientation: landscape) {
    padding: 0;
    align-items: center;
  }
}

.modal {
  // position: absolute;
  // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - rem(20px));
  margin-top: rem(84px);
  padding: rem(30px) rem(20px) rem(30px);
  background: #443127;
  border-radius: rem(20px);
  border: rem(3px) solid $gold;
  color: #fff;
  // box-shadow: 0px rem(10px) rem(30px) rgba(0, 0, 0, 0.5);
  &-check_photo {
    min-height: auto;
  }
  &__header {
    display: flex;
    justify-content: center;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    top: rem(-70px);
    left: 50%;
    transform: translateX(-50%);
    width: rem(48px);
    height: rem(48px);
    background-image: url("../assets/images/icons/close-2.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    transition: 0.4s;
    &:hover {
      transform: rotate(90deg);
    }
  }
  &__title {
    position: relative;
    display: flex;
    max-width: 100%;
    align-self: center;
    text-align: center;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    text-transform: uppercase;
    margin-bottom: rem(20px);
    font-size: rem(26px);
    line-height: 1.2;
    &._white {
      color: $grey;
    }
    small {
      max-width: 80%;
      margin-top: rem(10px);
      font-size: rem(16px);
      line-height: rem(22px);
      text-transform: none;
    }
    i {
      display: block;
      @include size(rem(6px));
      margin: 0 rem(13px) rem(5px);
      border-radius: 50%;
      background: $red;
    }
    & + .modal__text {
      margin-top: rem(-7px);
    }
  }
  &__success-icon {
    max-height: rem(80px);
    margin-bottom: rem(20px);
    width: auto;
    object-fit: contain;
  }
  &__premier {
    max-height: rem(180px);
  }
  &__subtitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: rem(6px);
    font-family: $font-reg;
    font-size: rem(14px);
    text-align: center;
    // span {
    //   display: block;
    //   flex: 1 0 auto;
    //   height: 1px;
    //   background: #000;
    //   &:first-of-type {
    //     margin-right: rem(10px);
    //   }
    //   &:last-of-type {
    //     margin-left: rem(10px);
    //   }
    // }
  }
  &__text {
    margin-bottom: rem(16px);
    text-align: center;
    font-family: $font-reg;
    font-size: rem(14px);
    line-height: 1.2;
  }
  &-signup {
    width: rem(758px);
  }

  &__another-option {
    margin: rem(20px) 0 rem(27px);
    text-align: center;
    a {
      border-bottom: 1px solid currentColor;
      &:hover {
        border-color: transparent;
      }
    }
  }
  &__footer {
    margin-top: rem(52px);
    text-align: center;
  }
  .success_modal {
    &__text {
      margin-top: 0;
      font-size: rem(20px);
      text-transform: none;
      margin-bottom: rem(20px);
      text-align: center !important;
    }
    &-back {
      font-size: rem(12px);
      text-transform: uppercase;
      text-align: center;
    }
    &-image {
      margin: auto;
    }
  }
  .error_modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: rem(14px);
    img {
      margin-bottom: rem(24px);
    }
    &-text {
      font-size: rem(14px);
      text-transform: uppercase;
      margin-bottom: rem(25px);
    }
  }
  .btn {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: rem(20px);
  }
  &__popover-text {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: rem(20px);
    background: #fff;
    color: $red;
    transform: translateY(-40px);
    transition: 0.6s ease-in-out;
    &:hover {
      transform: translateY(-100%);
    }
  }
  &-image {
    padding-top: rem(20px);
    padding-bottom: rem(20px);
    img {
      margin: auto;
      max-width: 100%;
      max-height: 55vh;
      width: auto;
      border-radius: rem(10px);
    }
  }
  &__iframe {
    position: relative;
    width: 100%;
    height: 70vh;
    overflow: hidden;
    margin-bottom: rem(30px);
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  &-voting {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: rem(33px);
    &_popup {
      .modal__image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        & > img {
          max-width: 100%;
          max-height: 55vh;
          width: auto;
          border-radius: rem(10px);
        }
      }
    }
    .btn {
      width: auto;
      margin: 0;
    }
  }
  &-rating {
    display: flex;
    align-items: center;
    height: rem(51px);
    padding: rem(22px);
    border: rem(2px) solid $yellow;
    margin-top: rem(20px);
    font-size: rem(22px);
    line-height: 1;
    color: $yellow;
    background: $brown;
    border-radius: rem(40px);
    cursor: pointer;
    span {
      padding-top: rem(5px);
    }
    svg {
      width: rem(22px);
      margin-left: rem(20px);
    }
  }
  @media (min-width: $md) and (orientation: landscape) {
    min-height: auto;
    // margin-top: 10vh;
    justify-content: flex-start;
    &--sm {
      width: rem(440px);
      padding: rem(57px) rem(50px);
    }
    &--md {
      width: rem(500px);
    }
    &--lg {
      width: rem(758px);
      padding: rem(40px) rem(25px) rem(46px);
    }
  }
  @media (min-width: $md) and (orientation: landscape) {
    min-height: auto;
    justify-content: flex-start;
    padding: rem(66px) rem(66px) rem(93px);
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.5);
    &--sm {
      width: rem(440px);
      padding: rem(57px) rem(50px);
    }
    &--md {
      width: rem(520px);
      padding: rem(40px) rem(60px);
    }
    &--lg {
      width: rem(573px);
      padding: rem(40px) rem(60px);

      &.modal-image {
        margin-top: 5vh;
      }
      &.modal-video {
        width: 70vw;
      }
      &.modal-copy_code {
        padding-top: rem(0px);
      }
    }
    &--max {
      width: rem(800px);
      padding: rem(60px) rem(80px);
    }
    &-voting_popup {
      margin-top: 5vh;
      max-height: 90vh;
    }
    &-temporarily_unavailable {
      .modal__title {
        font-size: rem(40px);
      }
      .modal__text {
        font-size: rem(20px);
        line-height: 1.5;
        b {
          font-size: rem(26px);
          text-transform: uppercase;
        }
      }
    }
    .btn {
      margin-top: 0;
    }
    &__title {
      max-width: 100%;
      text-align: center;
      margin-bottom: rem(20px);
      font-size: rem(36px);
      line-height: rem(43px);
    }
    &__text {
      // margin-top: rem(27px);
      font-size: rem(16px);
      line-height: 1.2;
    }
    &__close {
      width: rem(20px);
      height: rem(20px);
      top: rem(20px);
      right: rem(20px);
      left: auto;
      transform: translateX(0%);
      background-image: url("../assets/images/icons/close-white.svg");
      background-repeat: no-repeat;
      background-size: rem(20px);
      background-position: center;
    }
    &-pdf {
      max-height: 60vh;
    }
    &__list {
      width: 70%;
    }
    &__subtitle {
      margin-bottom: rem(20px);
      font-size: rem(18px);
      line-height: rem(22px);
      font-family: $font-reg;
    }
    &__another-option {
      margin: 0 0 0;
    }
    &-voting {
      flex-direction: row;
    }
    &-rating {
      display: flex;
      align-items: center;
      height: rem(71px);
      padding: rem(22px);
      border: rem(5px) solid $yellow;
      margin-top: 0;
      margin-left: rem(60px);
      font-size: rem(32px);
      line-height: 1;
      color: $yellow;
      background: $brown;
      border-radius: rem(40px);
      span {
        padding-top: rem(5px);
      }
      svg {
        width: rem(32px);
        margin-left: rem(20px);
      }
    }
  }
}
</style>
