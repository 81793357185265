<template>
  <section class="voting section" id="gallery">
    <img src="../assets/images/lines.svg" alt="" class="voting__lines">
    <div class="container">
      <div class="section-title">ФОТОГАЛЕРЕЯ</div>
      <div class="voting-nav">
        <!-- <button class="voting-nav__btn btn btn--primary btn--lg">Примеры работ</button> -->
        <button @click="addPhoto()" class="voting-nav__btn btn btn--primary btn--icon btn--lg">
          <span>добавить <span class="hidden-xs">свою</span> фотографию</span>
          <img
            class="btn__icon"
            src="../assets/images/icons/arrow_1.svg"
            alt=""
          />
        </button>
        <div class="voting-nav-dropdown" :class="{ open: openDropdown }">
          <div
            class="voting-nav-dropdown__trigger btn btn--primary btn--lg"
            @click="openDropdown = !openDropdown"
          >
            <span
              >сортировать:
              {{
                sortingList.filter((item) => item.value === sortBy)[0].text
              }}</span
            >
            <img
              class="btn__icon"
              src="../assets/images/icons/arrow_1.svg"
              alt=""
            />
          </div>
          <div class="voting-nav-dropdown__content">
            <ul class="voting-nav-dropdown__list">
              <li
                v-for="(opt, i) in sortingList"
                :key="i"
                class="voting-nav-dropdown__option"
                @click="setSorting(opt)"
              >
                {{ opt.text }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="voting-list">
        <div
          v-for="(item, i) in photos"
          :key="i"
          class="voting-list__item"
          @click="$modal.show('voting_popup', { src: item.img, rating: item.votes, id: item.id })"
        >
          <div class="voting-list__item-img-wrapper">
            <img :src="item.preview" alt="" class="voting-list__item-img" />
          </div>
          <div class="voting-list__item-rating">
            <span>{{ item.votes != -1 ? item.votes : '' }}</span>
            &nbsp;
            <svg
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.16712 17.7445C5.16712 18.3276 4.66206 18.8329 4.0793 18.8329H1.08781C0.505057 18.8329 0 18.3276 0 17.7445V9.23104C0 8.64793 0.505057 8.14257 1.08781 8.14257H4.0793C4.66206 8.14257 5.16712 8.64793 5.16712 9.23104V17.7445ZM17.8712 11.097C18.2209 11.3691 18.4151 11.7967 18.4151 12.2632C18.4151 12.9629 17.9101 13.5461 17.2885 13.7016C17.6381 13.9737 17.8324 14.4013 17.8324 14.8678C17.8324 15.5675 17.3273 16.1506 16.6669 16.3061C17.0165 16.5782 17.2108 17.0058 17.2108 17.4723C17.2108 18.2887 16.5503 18.9495 15.7345 18.9495L7.34275 18.8718C6.75999 18.8718 6.25493 18.3664 6.25493 17.7833V9.23104C6.25493 7.87045 10.5285 5.42139 10.7616 4.06079C10.917 3.20557 10.7227 0.989746 11.3055 0.989746C12.3156 0.989746 13.5977 1.37849 13.5977 3.59431C13.5977 5.53801 12.3156 8.10369 12.3156 8.10369H17.5216C18.3374 8.10369 18.9979 8.76455 18.9979 9.58091C19.0368 10.3584 18.5317 10.9415 17.8712 11.097Z"
                fill="#0C0403"
              />
              <path
                d="M5.16712 17.7445C5.16712 18.3276 4.66206 18.8329 4.0793 18.8329H1.08781C0.505057 18.8329 0 18.3276 0 17.7445V9.23104C0 8.64793 0.505057 8.14257 1.08781 8.14257H4.0793C4.66206 8.14257 5.16712 8.64793 5.16712 9.23104V17.7445Z"
                fill="#0C0403"
              />
              <path
                d="M17.8712 11.097C18.2209 11.3691 18.4151 11.7967 18.4151 12.2632C18.4151 12.9629 17.9101 13.5461 17.2885 13.7016C17.6381 13.9737 17.8324 14.4013 17.8324 14.8678C17.8324 15.5675 17.3273 16.1506 16.6669 16.3061C17.0165 16.5782 17.2108 17.0058 17.2108 17.4723C17.2108 18.2887 16.5503 18.9495 15.7345 18.9495L7.34275 18.8718C6.75999 18.8718 6.25493 18.3664 6.25493 17.7833V9.23104C6.25493 7.87045 10.5285 5.42139 10.7616 4.06079C10.917 3.20557 10.7227 0.989746 11.3055 0.989746C12.3156 0.989746 13.5977 1.37849 13.5977 3.59431C13.5977 5.53801 12.3156 8.10369 12.3156 8.10369H17.5216C18.3374 8.10369 18.9979 8.76455 18.9979 9.58091C19.0368 10.3584 18.5317 10.9415 17.8712 11.097Z"
                fill="#0C0403"
              />
            </svg>
          </div>
        </div>
        <div class="no-results" v-if="noResults">Вы еще не загружали фото</div>
      </div>
      <ul class="pagination" v-if="pages.length > 1">
        <li
          class="pagination-item"
          v-if="page != 1"
          @click="page--, paginate()"
        >
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.292893 7.29289C-0.0976315 7.68342 -0.0976315 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM15 7L1 7V9L15 9V7Z"
              fill="white"
            />
          </svg>
        </li>
        <li
          class="pagination-item"
          v-for="pageNumber in pages.length"
          :key="pageNumber"
          @click="(page = pageNumber), paginate()"
          :class="{
            current: page === pageNumber,
            last: pageNumber == pages.length && Math.abs(pageNumber - page) > 3,
            first: pageNumber == 1 && Math.abs(pageNumber - page) > 3,
            visible:
              Math.abs(pageNumber - page) < 3 ||
              pageNumber === pages.length ||
              pageNumber == 1,
          }"
        >
          {{ pageNumber }}
        </li>
        <li
          @click="page++, paginate()"
          v-if="page < pages.length"
          class="pagination-item"
        >
          <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L8.34315 15.0711C7.95262 15.4616 7.31946 15.4616 6.92893 15.0711C6.53841 14.6805 6.53841 14.0474 6.92893 13.6569L12.5858 8L6.92893 2.34315C6.53841 1.95262 6.53841 1.31946 6.92893 0.928932C7.31946 0.538408 7.95262 0.538408 8.34315 0.928932L14.7071 7.29289ZM0 7L14 7V9L0 9L0 7Z"
              fill="white"
            />
          </svg>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    publicPath: process.env.VUE_APP_URL,
    openDropdown: false,
    photos: [],
    pages: [],
    page: 1,
    noResults: false
  }),
  computed: {
    user() {
      return this.$store.getters.user;
    },
    sortBy() {
      return this.$store.getters.voting.sort;
    },
    pageFromGetter() {
      return this.$store.getters.voting.page;
    },
    sortingList() {
      let items;
      if (this.user) {
        items = [
          {
            text: "По рейтингу",
            value: "votes",
          },
          {
            text: "По дате",
            value: "date",
          },
          {
            text: "Мои фото",
            value: "my",
          },
        ]
      } else {
        items = [
          {
            text: "По рейтингу",
            value: "votes",
          },
          {
            text: "По дате",
            value: "date",
          },
        ]
      }
      return items;
    }
  },
  methods: {
    setSorting(opt) {
      this.$store.commit("SET_VOTING_PARAMS", { page: 1, sort: opt.value });
      this.openDropdown = false;
      this.page = 1
      this.$store.dispatch("GetGallery", { page: this.page, order: opt.value }).then((r) =>{
        this.photos = r.data.items;
        if (!this.photos.length) {
            this.noResults = true;
          } else {
            this.noResults = false;
          }
      });
    },
    setPages(total) {
      console.log(total);
      this.pages = [];
      if (total) {
        for (let index = 1; index <= total; index++) {
          this.pages.push(index);
        }
      }
    },
    paginate() {
      let page = this.page;
      this.$store.commit("SET_VOTING_PARAMS", { page: this.page, sort: this.sortBy });
      this.$store.dispatch("GetGallery", { page: page }).then((r) =>{
        this.photos = r.data.items;
      });
      
      console.log(this.photos);
    },
    addPhoto() {
      if (this.$store.getters.user) {
        this.$modal.show("upload_photo");
      } else {
        this.$modal.show("authorization");
      }
    }
  },
  mounted() {
    this.$store.dispatch("GetGallery", { page: this.page }).then((res) => {
      this.setPages(res.data.pages);   
      this.photos = this.$store.getters.gallery;
    });
  }
};
</script>

<style lang="scss" scoped>
.voting {
  position: relative;
  padding-top: rem(65px);
  padding-bottom: rem(60px);
  z-index: 1;
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
  }
  &::before {
    top: -12%;
    right: rem(-30px);
    width: rem(400px);
    height: rem(400px);
    background: url("../assets/images/dust-3.png") no-repeat center right;
    background-size: contain;
    pointer-events: none;
    z-index: -1;
  }
  &::after {
    top: 35%;
    left: rem(-50px);
    width: rem(300px);
    height: rem(400px);
    background: url("../assets/images/dust-4.png") no-repeat center left;
    background-size: contain;
    pointer-events: none;
    z-index: -1;
  }
  &__lines {
    position: absolute;
    top: calc(100% - rem(130px));
    left: 50%;
    transform: translateX(-50%);
    width: 200%;
    max-width: none;
  }
  &-nav {
    display: flex;
    flex-direction: column;
    margin-top: rem(50px);
    margin-bottom: rem(50px);
    &__btn {
      margin-bottom: rem(27px);
      padding-left: rem(15px);
      font-size: rem(16px);
      letter-spacing: normal;
      span {
        margin: auto;
      }
    }
    &-dropdown {
      position: relative;
      flex: 0 0 calc(33% - #{rem(20px)});
      &__trigger {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 rem(80px) 0 rem(25px);
        font-size: rem(16px);
        svg {
          position: absolute;
          top: 50%;
          right: rem(40px);
          transform: translateY(-50%);
          width: rem(22px);
          margin-left: rem(17px);
          path {
            stroke: $yellow;
            transition: 0.4s;
          }
        }
        &:hover,
        .open & {
          svg {
            path {
              stroke: #000;
            }
          }
        }
        .open & {
          background: $yellow;
          color: #000;
          svg {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
      &__content {
        position: absolute;
        top: 100%;
        left: 10%;
        width: 80%;
        opacity: 0;
        transition: 0.4s;
        pointer-events: none;
        background: #FFF;
        border-radius: rem(10px);
        overflow: hidden;
        z-index: 1;
        .open & {
          pointer-events: all;
          opacity: 1;
        }
      }
      &__option {
        padding: rem(17px) rem(20px);
        line-height: rem(16px);
        font-size: rem(16px);
        color: #0B0909;
        text-transform: none;
        font-family: $font-reg;
        transition: 0.4s;
        cursor: pointer;
        &:hover {
          background: #FFE189;
          color: #000;
        }
      }
    }
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0 0 rem(40px);
    &__item {
      width: rem(224px);
      margin: 0 rem(14px) rem(70px);
      padding: rem(17px) rem(14px) 0;
      background: #eae7e2;
      border-radius: rem(10px);
      cursor: pointer;
      &-img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: rem(10px);
        &-wrapper {
          position: relative;
          &::after {
            content: "";
            width: 0;
            display: block;
            padding-top: 100%;
          }
        }
      }
      &-rating {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(5px) 0;
        font-size: rem(16px);
        color: #0c0403;
        font-family: "OpiumNew-Regular";
        svg {
          width: rem(12px);
          margin-left: rem(9px);
        }
      }
    }
  }
  &__show-more {
    width: 50%;
    margin: 0 auto;
  }
  @media (orientation: landscape) {
    padding-top: rem(80px);
    padding-bottom: rem(10px);
    &::before {
      top: -25%;
      right: 0;
      width: rem(400px);
      height: rem(700px);
    }
    &::after {
      top: 27%;
      left: 0;
      width: rem(300px);
      height: rem(650px);
    }
    &__lines {
      top: calc(100% - rem(130px));
      width: 115%;
    }
    .section-title {
      margin-bottom: rem(70px);
    }
    &-nav {
      flex-direction: row;
      justify-content:center;
      width: rem(1190px);
      gap: 0 rem(80px);
      margin: 0 auto;
      margin-top: 0;
      margin-bottom: rem(60px);
      &__btn {
        flex: 0 0 calc(35% - #{rem(18px)});
        margin: 0 0;
        padding: 0 rem(30px);
        font-size: rem(18px);
        height: rem(60px);
        span {
          margin: 0;
        }
      }
      &-dropdown {
        flex: 0 0 calc(35% - #{rem(18px)});
        &__trigger {
          font-size: rem(18px);
        }
        &__content {
          border-radius: rem(20px);
        }
        &__option {
          padding: rem(17px) rem(20px);
          font-size: rem(18px);
        }
      }
    }
    &-list {
      width: rem(1190px);
      margin: 0 auto;
      &__item {
        width: rem(224px);
        margin: 0 rem(35px) rem(70px);
        padding: rem(20px) rem(20px) 0;
        &-rating {
          font-size: rem(24px);
          svg {
            width: rem(19px);
          }
        }
      }
    }
    &__show-more {
      width: auto;
      margin-right: 0;
      margin-left: auto;
    }
  }
  .no-results {
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem(300px);
    font-size: rem(32px);
  }
}
</style>
