<template>
  <label class="form-checkbox">
    <input
      type="checkbox"
      class="form-checkbox__input"
      @change="onInput()"
      v-model.trim="retValue"
    />
    <div class="form-checkbox__custom"></div>
    <div class="form-checkbox__caption" v-html="label"></div>
    <div class="error-hint _left" v-if="error">{{ error }}</div>
  </label>
</template>

<script>
export default {
  data() {
    return {
      retValue: this.field,
    };
  },

  methods: {
    errorReset() {
      this.$parent.errorReset();
    },

    onInput() {
      this.$emit("input", this.retValue);
    },
  },
  props: {
    field: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    error: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.form-checkbox {
  position: relative;
  cursor: pointer;
  display: flex;

  &__input {
    position: absolute;
    left: -9999px;
    opacity: 0;
    visibility: hidden;
  }

  &__input:checked + .form-checkbox__custom {
    &::after {
      opacity: 1;
    }
    &::before {
      background: linear-gradient(90deg, #eadcbf 0%, #a1895f 100%);
    }
  }

  &__custom {
    position: relative;
    min-width: rem(22px);
    width: rem(22px);
    height: rem(22px);
    border-radius: rem(6px);
    transition: background-image 0.5s ease-in-out;
    z-index: 1;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: rem(6px);
      background: #fff;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      border-radius: rem(6px);
      background-image: url("../../assets/images/icons/checked_icon.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: rem(12px);
      position: absolute;
      top: 0;
      left: 0;
      transition: 0.2s;
      opacity: 0;
      z-index: -1;
    }
    .error & {
      &::before {
        background: rgba(#E31D36, 0.3);
      }
    }
  }

  &__caption {
    font-size: rem(16px);
    line-height: rem(17px);
    margin-top: rem(3px);
    margin-left: rem(12px);
    font-family: $font-reg;
    text-transform: none;
    a {
      color: $pink;
    }
  }
  @media (orientation: landscape) {
    margin-bottom: 0;
    &__custom {
      min-width: rem(24px);
      width: rem(24px);
      height: rem(24px);
      border-radius: rem(6px);
      &::after {
        background-size: rem(15px);
      }
    }
    &__caption {
      margin-left: rem(9px);
      font-size: rem(16px);
      line-height: 1.3;
    }
  }
}

.error .form-checkbox__custom {
  background: #ff9999;
}
</style>
