<template>
  <section class="partners section" id="partners">
    <div class="container _small-width">
      <div class="section-title">сети-ПАртнеры</div>
      <div class="partners-list">
        <div v-for="(item, i) in partners" :key="i" class="partners-list__item">
          <div class="partners-list__item-logo-wrapper">
            <img
              :src="require(`../assets/images/partners/${item.img}`)"
              alt=""
              class="partners-list__item-logo"
            />
          </div>
          <div class="partners-list__item-title" v-html="item.title"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data: () => ({
    partners: [
      {
        img: "logo-1.png",
        title: "период регистрации чеков: <br>01.06-31.07.2024",
      },
      {
        img: "logo-2.png",
        title: "период регистрации чеков: <br>01.07-31.07.2024 ",
      },
      {
        img: "logo-3.png",
        title: "период регистрации чеков: <br>01.06-31.07.2024",
      },
      {
        img: "logo-4.png",
        title: "период регистрации чеков: <br>01.06-31.07.2024",
      },
      {
        img: "logo-5.png",
        title: "период регистрации чеков: <br>01.07-31.07.2024",
      },
    ],
  }),
  methods: {
    afterChange(slideIndex) {},
  },
};
</script>

<style lang="scss" scoped>
.partners {
  padding-top: rem(58px);
  z-index: 11;
  @media (orientation: landscape) {
    padding-top: rem(48px);
  }
  &-list {
    width: 100%;
    margin-bottom: rem(10px);
    padding-top: rem(10px);
    gap: rem(40px);
    @media (orientation: landscape) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-left: 0;
      padding-top: rem(7px);
      margin-bottom: rem(20px);
    }
    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      flex: 0 0 30%;
      @media (max-width: $md) {
        padding: 0 rem(20px);
        &:not(:last-of-type) {
          margin-bottom: rem(30px);
        }
      }
      &-content {
        @media (max-width: $md) {
          width: 100%;
          padding: rem(30px);
        }
      }
      &-title {
        font-size: rem(12px);
        line-height: rem(15px);
        font-family: $font-reg;
        span {
          width: 70%;
        }
        @media (max-width: $md) {
          font-size: rem(12px);
          line-height: rem(17px);
        }
      }

      &-logo {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        &-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto rem(20px);
          height: rem(84px);
          @media (min-width: $md) {
            width: rem(150px);
            height: rem(93px);
          }
        }
      }
      .btn {
        @media (orientation: landscape) {
          padding: 0 rem(35px);
        }
      }
    }
  }
}
</style>
