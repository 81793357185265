<template>
  <section class="steps section" id="steps">
    <div class="steps__content">
      <div class="container">
        <div class="steps__list">
          <div v-for="(item, i) in steps" :key="i" class="steps__item">
            <div class="steps__item-index">{{ i + 1 }}</div>
            <div class="steps__item-title" v-html="item.title"></div>
            <div
              class="steps__item-text"
              v-if="item.text"
              v-html="item.text"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      steps: [
        {
          title: "КУПИТЕ",
          text: "любой кофе Jardin <br>в&nbsp;сетях-ПАРТнЕРах",
        },
        {
          title: "зарегистрируйте",
          text: "чек на сайте",
        },
        {
          title: "получИТЕ",
          text: "Гарантированный приз",
        },
        {
          title:
            'УЧАСТВУЙТЕ <br class="visible-xs">В&nbsp;РОЗЫГРЫШЕ<br class="hidden-xs">ПРИЗОВ',
        },
      ],
    };
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.steps {
  padding-top: rem(35px);
  background: linear-gradient(to bottom, #010000 11.92%, #271711 65.48%);
  &__list {
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    &:not(:last-of-type) {
      margin-bottom: rem(48px);
    }
    &-index {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @include size(rem(52px));
      margin-bottom: rem(20px);
      padding-top: rem(4px);
      background: $gold;
      border-radius: 50%;
      font-size: rem(30px);
      line-height: 1;
    }
    &:first-of-type {
      .steps__item-index::before {
        display: none;
      }
    }
    &:last-of-type {
      .steps__item-index::after {
        display: none;
      }
    }
    &-title {
      width: 100%;
      color: $yellow;
      font-size: rem(20px);
      line-height: rem(24px);
      margin-bottom: rem(4px);
    }
    &-text {
      font-family: $font-reg;
      font-size: rem(14px);
      line-height: rem(17px);
    }
  }
  @media (orientation: landscape) {
    padding-top: rem(20px);
    background: none;
    z-index: 10;
    &::before {
      content: '';
      position: absolute;
      top: 70%;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, #010000 11.92%, #271711 65.48%);
    }
    &__list {
      display: flex;
      text-align: center;
      justify-content: space-between;
    }
    &__item {
      flex: 0 0 25%;
      margin-bottom: 0;
      &-index {
        margin-bottom: rem(28px);
        &::before,
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          display: block;
          width: rem(150px);
          height: 2px;
          background: #e4c9b5;
        }
        &::before {
          right: calc(100% + #{rem(21px)});
        }
        &::after {
          left: calc(100% + #{rem(21px)});
        }
      }
      &-title {
        margin-bottom: rem(5px);
        font-size: rem(24px);
        line-height: rem(29px);
      }
      &-text {
        font-size: rem(18px);
        line-height: rem(22px);
      }
    }
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 3000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
