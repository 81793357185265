<template>
  <Modal name="upload_photo" title="загрузка фото" @closeModal="closeModal" size="md">
    <template v-slot:content>
      <div class="modal__text">На&nbsp;загружаемых фотографиях должно быть изображение участника акции и&nbsp;продукта. Каждая фотография может участвовать в&nbsp;акции только один раз. На&nbsp;фото не&nbsp;должно быть нарушений авторских прав, ничего вульгарного или непристойного. Также нельзя использовать Photoshop или другие графические программы или нейросети для создания фотографий. Подробные условия участия смотрите в&nbsp;<a :href='`${publicPath}docs/rules.pdf`' class='_undeline-link _bold' target='_blank'>правилах&nbsp;акции</a>.</div>
      <InputPhoto
        :chosenFileName="chosenFileName"
        @submitMedia="submitMedia"
        @deleteFile="deleteFile"
      />
      <button
        type="button"
        class="btn btn--primary btn--loader-icon w-100"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING', _disabled: !attach }"
      >
        Отправить
        <img
          src="../../assets/images/icons/spinner.svg"
          class="loader"
          alt=""
        />
        <img
          class="btn__icon"
          src="../../assets/images/icons/arrow_1.svg"
          alt=""
        />
      </button>
    </template>
  </Modal>
</template>

<script>
import InputPhoto from '../form/inputPhoto.vue';
import Modal from "@/components/Modal.vue";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    Modal, InputPhoto
  },
  data: function () {
    return {
      attach: '',
      publicPath: process.env.BASE_URL,
      submitStatus: null,

      chosenFileName: {},
      errorMessage: {
        attach: null,
      }
    };
  },
  validations: {
    attach: { required },
  },
  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        attach: null,
      };
    },
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
    },
    submitMedia(e) {
      this.chosenFileName = e;
      console.log(e[0]);
      this.attach = e[0];
    },
    deleteFile() {
      this.chosenFileName = {};
      this.attach = "";
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";
        const formData = new FormData();

        formData.append("img", this.attach);
        const data = {
          fields: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };
        this.$store
          .dispatch("UploadImage", data)
          .then((r) => {
            console.log(r);
            if (!r.result) {
              this.submitStatus = null;

              this.captcha = null;
              let fieldsError;
              if (r.error && r.error.img) {
                [this.errorMessage.attach] = r.error.img;
                fieldsError = true;
              } 
              if (r.error && !fieldsError) {
                this.$modal.hide("upload_photo");
                this.$modal.show("common_error", {
                  text: r.error,
                });
              }
              
            } else {
              this.$modal.hide("upload_photo");
              this.$modal.show("common_success", {
                title: "фото успешно загружено",
                text: `Проверка фото может заниматьдо 72&nbsp;часов. После того, как Фото пройдет модерацию, вы&nbsp;получите письмо на&nbsp;почту, указанную при регистрации`,
                btnClose: true,
                successIcon: true,
              });
              this.$store.dispatch("GetProfile");
      
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
};
</script>
