<template>
  <section class="prizes section" id="prizes">
    <div class="container">
      <div class="prize prize--1">
        <div class="prize__title">гарантированные призы</div>
        <img
          src="../assets/images/prizes/prize-1.png"
          alt=""
          class="prize__img"
        />
        <div class="prize__subtitle">Денежные призы</div>
        <div class="prize__text">
          пополнение баланса номера <br />мобильного телефона
        </div>
      </div>
      <div class="prizes__row">
        <div class="prize prize--2">
          <div class="prize__title">ЕЖЕНЕДЕЛЬНЫЙ <br />РОЗЫГРЫШ</div>
          <img
            src="../assets/images/prizes/prize-2.png"
            alt=""
            class="prize__img"
          />
          <div class="prize__text">
            50 000₽ на&nbsp;БАНКОВСКУЮ КАРТУ <br />ПОБЕДИТЕЛЯ
          </div>
        </div>
        <div class="prize prize--3">
          <div class="prize__title">СПЕЦИАЛЬНЫЙ <br />РОЗЫГРЫш</div>
          <img
            src="../assets/images/prizes/prize-3.png"
            alt=""
            class="prize__img hidden-xs"
          />
          <img
            src="../assets/images/prizes/prize-3-mob.png"
            alt=""
            class="prize__img visible-xs"
          />
          <div class="prize__text">
            Сертификат М.видео на&nbsp;30&nbsp;000&nbsp;₽
            <br />за&nbsp;регистрацию 3&nbsp;и&nbsp;более чеков
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.prizes {
  position: relative;
  padding-top: rem(60px);
  &::before,
  &::after {
    content: "";
    position: absolute;
    display: block;
  }
  &::before {
    top: -12%;
    right: rem(-110px);
    width: rem(400px);
    height: rem(550px);
    background: url("../assets/images/dust-2.png") no-repeat center right;
    background-size: contain;
  }
  &::after {
    top: 10%;
    left: 0;
    width: rem(300px);
    height: rem(600px);
    background: url("../assets/images/dust-1.png") no-repeat center left;
    background-size: contain;
  }
  @media (orientation: landscape) {
    &::before {
      top: -20%;
      right: 0;
      width: rem(400px);
      height: rem(850px);
    }
    &::after {
      top: 18%;
      width: rem(300px);
      height: rem(750px);
    }
  }
  &__row {
    margin: auto;
    display: flex;
    flex-direction: column;
    @media (orientation: landscape) {
      width: 90%;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
.prize {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &--1 {
    margin-bottom: rem(40px);
    @media (orientation: landscape) {
      margin-bottom: rem(73px);
    }
  }
  &--2 {
    margin-bottom: rem(65px);
    @media (orientation: landscape) {
      width: 50%;
      margin-bottom: 0;
    }
  }
  &--3 {
    @media (orientation: landscape) {
      width: 50%;
    }
  }
  &__title {
    width: 90%;
    margin: auto;
    margin-bottom: rem(30px);
    font-size: rem(26px);
    line-height: rem(31px);
    text-align: center;
    @media (orientation: landscape) {
      width: 100%;
      margin-bottom: rem(13px);
      font-size: rem(50px);
      line-height: rem(65px);
    }
  }
  &__subtitle {
    margin-bottom: rem(10px);
    color: $yellow;
    font-size: rem(20px);
    line-height: rem(24px);
    @media (orientation: landscape) {
      margin-bottom: rem(12px);
      font-size: rem(24px);
    }
  }
  &__text {
    font-family: $font-reg;
    font-size: rem(14px);
    line-height: rem(17px);
    @media (orientation: landscape) {
      font-size: rem(18px);
      line-height: rem(23px);
    }
  }
  &__img {
    max-width: 100%;
    margin-bottom: rem(35px);
    @media (orientation: landscape) {
      height: rem(305px);
      margin-bottom: rem(37px);
    }
    .prize--2 & {
      max-width: none;
      height: rem(380px);
      margin: rem(-67px) 0 rem(-60px) rem(125px);
      @media (orientation: landscape) {
        height: rem(475px);
        margin: rem(-80px) 0 rem(-80px) rem(125px);
      }
    }
    .prize--3 & {
      max-width: none;
      height: rem(285px);
      margin: rem(10px) 0 rem(-7px) rem(80px);
      @media (orientation: landscape) {
        height: rem(420px);
        margin: rem(-40px) 0 rem(-65px) rem(85px);
      }
    }
  }
}
</style>
