<template>
  <section class="hero section" id="home">
    <div class="container">
      <div class="hero__row">
        <div class="hero__title visible-xs">ЛЕТО ПРИЗОВ ОТ Jardin</div>
        <img
          src="../assets/images/hero/products.png"
          alt=""
          class="hero__products"
        />
        <div class="hero__content">
          <h1 class="hero__title hidden-xs">ЛЕТО ПРИЗОВ ОТ Jardin</h1>
          <button @click="$metrika.reachGoal('register_receipt_1_screen'), toCheckUpload()" class="btn btn--primary btn--icon">
            <span>Зарегистрировать чек</span>
            <img
              class="btn__icon"
              src="../assets/images/icons/arrow_1.svg"
              alt=""
            />
          </button>
          <div @click="openInNewTab()" class="btn btn--primary btn--icon">
            <span>ПЕРЕЙТИ В ЧАТ-БОТ</span>
            <img class="btn__icon" src="../assets/images/icons/tg.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    openInNewTab() {
      this.$metrika.reachGoal('bot');
      this.adhighChatBot();
      this.doGoalVK('chatbot');
      window.open('https://t.me/jardin_priz_bot', '_blank').focus();
    }
  },
  mounted() {
    // this.toCheckUpload()
  },
};
</script>
<style lang="scss" scoped>
.hero {
  position: relative;
  // height: calc(var(--vh, 1vh) * 100);
  // padding-bottom: rem(100px);
  z-index: 9;
  display: flex;
  align-items: flex-start;
  background: url("../assets/images/hero/hero-mob.jpg") no-repeat center top;
  background-size: 100%;
  .container {
    position: relative;
    padding-top: rem(65px);
    padding-bottom: rem(0px);
    height: 100%;
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__row {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: auto;
    flex-direction: column;
  }
  &__col {
    position: relative;
  }
  &__products {
    width: auto;
    height: rem(237px);
    object-fit: contain;
    margin-bottom: rem(28px);
  }
  &__title {
    text-align: center;
    margin-bottom: rem(25px);
    font-family: $font-bold;
    font-size: rem(26px);
  }
  .btn {
    flex-shrink: 0;
    order: 1;
    width: 94%;
    &:not(:last-child) {
      margin-bottom: rem(28px);
    }
  }

  @media (orientation: portrait) {
    background-size: cover;
  }

  @media (orientation: landscape) {
    height: calc(var(--vh, 1vh) * 71);
    padding-top: 0;
    background: none;
    padding-bottom: 0;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: url("../assets/images/hero/hero.jpg") no-repeat center bottom;
      background-size: cover;
    }
    .container {
      padding-bottom: 0;
      display: flex;
      padding-top: vh(90px);
      height: 100%;
      align-items: flex-start;
    }
    &__content {
      margin-top: vh(80px);
    }
    &__row {
      max-width: 100%;
      flex-direction: row;
    }
    &__products {
      margin: 0 3% 0 7%;
      height: 97%;
    }
    &__title {
      margin-bottom: rem(45px);
      text-transform: uppercase;
      font-size: rem(52px);
    }
    .btn {
      width: rem(313px);
      margin-bottom: rem(25px);
    }
  }
  @media (max-width: $xlg) {
  }
}
</style>
